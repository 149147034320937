.App {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 900px) {
}

@media screen and (min-width: 900px) {
}
