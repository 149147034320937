/*
@font-face {
  font-family: "思源黑体";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/font/SourceHanSans-Normal.otf") format("woff2");
}

@font-face {
  font-family: "思源黑体";
  font-style: bold;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/font/SourceHanSans-Bold.otf") format("woff2");
}

@font-face {
  font-family: "思源黑体";
  font-style: Regular;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/font/SourceHanSans-Regular.otf") format("woff");
} */

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Source Han Sans", "Noto Sans CJK SC", "HanHei SC",
    "Microsoft YaHei", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", "PingFang SC", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: "Source Han Sans", "Noto Sans CJK SC", "HanHei SC", "PingFang SC",
    sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}
